import React from "react"
import Layout from "./Layout"
import SEO from "./Seo"
import RecentProjects from "./components/LastProjects"
import Skills from "./components/skills"
import imgOriol from "../images/oriol-hilari.jpg"
import PageTransition from "gatsby-v2-plugin-page-transitions"
// import history from "../helpers/history"

const ageCalc = date => {
  let diff_ms = Date.now() - date.getTime()
  let age_dt = new Date(diff_ms)
  return Math.abs(age_dt.getUTCFullYear() - 1970)
}
const IndexPage = () => (
  <Layout>
    <SEO
      title="Senior Front End Developer"
      description="Hi! My name is Oriol Hilari and I’m a Web Developer based in Barcelona. I’m specialized in web application development working with TDD, React, Redux, LESS/SASS and more!"
    />
    <PageTransition>
      <div>
        <h1>
          Hi, I'm Oriol.
          <br></br>Front End{" "}
          <span className="underline--magical">Developer</span>
          <br></br>in constant training.
          <br></br>
          <span className="state-profile">
            Working remotely{" "}
            <span role="img" aria-label="Working remotely">
              👨🏼‍💻
            </span>{" "}
            Based in Barcelona{" "}
            <span role="img" aria-label="Home office">
              🏠
            </span>
          </span>
        </h1>
        <div className="div-profile-img">
          <img
            className="profile-img"
            src={imgOriol}
            alt="Oriol Hilari - Front End Developer"
            loading="lazy"
          />
        </div>
        <div className="row-bio">
          <div className="col-bio">
            <p className="p-bio">
              My name is Oriol and I'm {ageCalc(new Date(1992, 8, 13))} years
              old. Currently I work as Senior Front End Developer at Fotocasa
              developing web applications with React, Redux, TDD, Clean
              Architecture & SSR.
            </p>
          </div>
          <div className="col-bio">
            <p className="p-bio">
              I'm a self-taught person very curious about new technologies.
              At the moment I am more focused in Front but also
              specializing in back-end. My work is my passion!
            </p>
          </div>
        </div>
      </div>
      <Skills />
      <RecentProjects />
    </PageTransition>
  </Layout>
)

export default IndexPage
